import { render, staticRenderFns } from "./document-filter-component.vue?vue&type=template&id=0ec433a5&scoped=true&"
import script from "./document-filter-component.ts?vue&type=script&lang=ts&"
export * from "./document-filter-component.ts?vue&type=script&lang=ts&"
import style0 from "./document-filter-component.vue?vue&type=style&index=0&id=0ec433a5&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ec433a5",
  null
  
)

export default component.exports