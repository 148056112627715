import { render, staticRenderFns } from "./dl-versnijding-parent.vue?vue&type=template&id=1c8d070a&scoped=true&"
import script from "./dl-versnijding-parent.ts?vue&type=script&lang=ts&"
export * from "./dl-versnijding-parent.ts?vue&type=script&lang=ts&"
import style0 from "./dl-versnijding-parent.vue?vue&type=style&index=0&id=1c8d070a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c8d070a",
  null
  
)

export default component.exports